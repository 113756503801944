import React from "react";

import { useSession } from "next-auth/react"
import { useRouter } from 'next/router'

import { LoadingComponentFullScreen } from '../src/components/LoadingScreen'

import * as permissions_data from './permissions_data'
import posthog from 'posthog-js'

export function AuthPageGate({ children, permissions, isPublic }) {
    const router = useRouter()
    const { hasPermission, user, status } = usePermissions(permissions)
    const isLogin = router.pathname.startsWith('/auth')

    React.useEffect(() => {
        if(user){
            posthog.identify(
                user.id,    
                { role: user.role, tenant: user.tenantId }
            );
        }
        if (status === "loading" || isPublic)
            return

        if (isLogin && user) {
            router.push(router.query?.callbackUrl ?? "/")
            return
        }
        if (!user && !isLogin) {
            router.push('/auth/login?error=SessionTimeout&callbackUrl=' + ((router.asPath) ? encodeURI(router.asPath) : "/"))
            return
        }
        if (!hasPermission) {
            router.push('/auth/error')
            return
        }
    }, [status, hasPermission, user])

    if (isPublic || isLogin) return children;
    if (status == "loading")
        return (<LoadingComponentFullScreen />)
    return children
}

export function AuthGate({ children, permissions }) {
    const { hasPermission, user, status } = usePermissions(permissions)


    if (status == "loading")
        return children

    if (hasPermission)
        return children
    return null
}

export function usePermissions(permissions) {
    const { data: session, status } = useSession()
    if (status == "loading")
        return {
            hasPermission: true,
            user: null,
            status: status
        }

    const user = session?.user

    return {
        hasPermission: userHasPermission(user, permissions),
        user: user,
        status: status
    }
}

function userHasPermission(user, requiredPermissions) {
    if (!requiredPermissions)
        return true

    if (!Array.isArray(requiredPermissions))
        requiredPermissions = [requiredPermissions]

    if (requiredPermissions.length == 0)
        return true

    if (!user?.role)
        return false

    const userPermissions = permissions_data.PERMISSION_ROLES_RELATION[user.role]

    const intersectionPermissions = userPermissions.filter((permission) => requiredPermissions.includes(permission))

    return intersectionPermissions.length > 0
}

