//ROLES
export const ADMIN_ROLE = 1
export const PROGRAMMER_ROLE = 2
export const COMPANY_ROLE = 3
export const COMPANY_EMPLOYEE_ROLE = 4


//PERMISSION
export const PROGRAMMER = 1
export const TEST_MANAGER = 2
export const RECRUITMENT_MANAGER = 3
export const RECRUITER = 4
export const CANDIDATE = 5
export const ADMIN = 6


//RELATION
export const PERMISSION_ROLES_RELATION = {
1: [1,2,4,3,5,6],
2: [1,5],
3: [1,2,4,5,3],
4: [1,2,4,5],
}