import axios from "axios";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGetRequest, buildGetRequest, buildPostRequest } from '../baseHooks';

export function createCandidate({ req }, tenantId, data) {
    return buildPostRequest([tenantId, 'candidates'], data, req)
}

export function createOwnCandidate({ req }, data) {
    return buildPostRequest(['candidates', 'create'], data, req)
}

export function editCandidate({ req }, tenantId, candidateId, data) {
    return buildPostRequest([tenantId, 'candidates', candidateId], data, req)
}

export function useGetCandidateByTenant(tenantId, candidateId) {
    return useGetRequest([tenantId, 'candidates', candidateId], getCandidateByTenant({}, tenantId, candidateId))
}

export function getCandidateByTenant({ req }, tenantId, candidateId) {
    return buildGetRequest([tenantId, 'candidates', candidateId], [], req)
}

export function useGetOwnCandidate() {
    return useGetRequest(['candidates'], getOwnCandidate({}))
}

export function getOwnCandidate({ req }) {
    return buildGetRequest(['candidates'], [], req)
}

export function editOwnCandidate({ req }, data) {
    return buildPostRequest(['candidates'], data, req)
}

export function getCandidates({ req }, tenantId, name = null, email = null, linkedinProfileLink = null, selectionProcessId = null) {
    const query_params = []
    if (name != null) 
        query_params.push({ 'key': 'name', 'value': name })
    if (email != null)
        query_params.push({ 'key': 'email', 'value': email })
    if (linkedinProfileLink != null)
        query_params.push({ 'key': 'linkedin_profile_link', 'value': linkedinProfileLink })
    if (selectionProcessId != null)
        query_params.push({ 'key': 'selection_process_id', 'value':  selectionProcessId})

    return buildGetRequest([tenantId, 'candidates'], query_params, req)
}

export function useGetCandidates(tenantId) {
    return useMutation((searchValues) => {
        return getCandidates({}, tenantId, searchValues.name, searchValues.email, searchValues.linkedinProfileLink, searchValues.selectionProcessId)()
    })
}

export function useCandidateLinking(){
    return useMutation((selectionProcessInviteId) => {
        const params = {
            method: 'POST',
            withCredentials: true,
            url: process.env.NEXT_PUBLIC_API_URL + `candidates/${selectionProcessInviteId}`,
        }
        return axios.post(params)
    })
}

export function useCreateCandidate() {
    return useMutation((data) => {
        const params = {
            method: 'POST',
            withCredentials: true,
            url: process.env.NEXT_PUBLIC_API_URL + `candidates/create`,
            data: data,
        }
        return axios(params)
    })
}
