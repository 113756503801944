import React from "react";

import { useGetOwnCandidate } from "data/selection_process/candidate";

import { useSession } from "next-auth/react"
import { useRouter } from 'next/router'

import * as permissions_data from './permissions_data'

export function CompleteProfileGate({ children }) {
    const router = useRouter()
    const { data: session, status: statusSession } = useSession()
    const { data: candidate, status: statusCandidate } = useGetOwnCandidate()
    const isProfile = router.pathname.startsWith('/profile')
    
    
    if (statusCandidate == "success" && statusSession == "authenticated" && candidate.linkedin_profile_link == "" && !isProfile && session?.user?.role == permissions_data.PROGRAMMER_ROLE){
        router.push('/profile?callbackUrl=' + ((router.asPath) ? encodeURI(router.asPath) : "/"))
        return null
    }

    return children
}
