import React from 'react';

import 'simplebar/src/simplebar.css';
import "components/Kanban/Kanban.css";

import { CacheProvider } from '@emotion/react';

import posthog from 'posthog-js'
// next
import Head from 'next/head';
// material
// contexts
import { SettingsProvider } from 'src/contexts/SettingsContext';
import { CollapseDrawerProvider } from 'src/contexts/CollapseDrawerContext';
// theme
import ThemeConfig from 'src/theme';
// utils
import createEmotionCache from 'src/utils/createEmotionCache';

import LoadingScreen from 'src/components/LoadingScreen';
import TopProgressBar from 'src/components/TopProgressBar';
import ThemePrimaryColor from 'src/components/ThemePrimaryColor';
import ChatwootWidget from 'components/ChatwootWidget';
import { SnackbarProvider, useSnackbar } from 'notistack'

import { Hydrate, QueryClient, QueryClientProvider, QueryCache } from 'react-query'

import { SessionProvider } from "next-auth/react"

import { AuthPageGate } from "../config/auth"

import TagManager from 'react-gtm-module';

import { CompleteProfileGate } from 'config/complete_profile';

// ----------------------------------------------------------------------

const clientSideEmotionCache = createEmotionCache();

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}) {
  const [queryClient] = React.useState(() => new QueryClient({
    queryCache: new QueryCache({

      onError: (error) => {
      }

    }),

  }))

  React.useEffect(() => {
    posthog.init('phc_29MYRQrB7Ve4KQkob89eVLGLA9LZabuZYz46mnwYzZ8', { api_host: 'https://app.posthog.com' })
  }, []);

  React.useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NW95BGL' });
  }, []);

  return (
    <SessionProvider session={session}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <CacheProvider value={clientSideEmotionCache}>
            <Head>
              <meta
                name='viewport'
                content='initial-scale=1, width=device-width'
              />
            </Head>

            <ThemeConfig>
              <ChatwootWidget />
              <ThemePrimaryColor>
                <LoadingScreen />
                <TopProgressBar />
                <SnackbarProvider maxSnack={3}>
                  <AuthPageGate isPublic={Component?.auth?.isPublic} permissions={Component?.auth?.permissions}>
                    <QueryClientProvider client={queryClient}>
                      <CompleteProfileGate>
                        <Hydrate state={pageProps.dehydratedState}>
                          <Component {...pageProps} />
                        </Hydrate>
                      </CompleteProfileGate>
                    </QueryClientProvider>
                  </AuthPageGate>
                </SnackbarProvider>
              </ThemePrimaryColor>
            </ThemeConfig>
          </CacheProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </SessionProvider>
  );
}


