import { forwardRef } from "react";
import PropTypes from "prop-types";
// material
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ sx }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box ref={ref} sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" viewBox="0 0 192 192" enableBackground="new 0 0 192 192">
        <path fill="#F55959" opacity="1.000000" stroke="none"
          d="M192.722473,10.732233 C193.000000,51.020893 193.000000,91.041786 193.000000,131.531342 C192.041122,132.913559 191.167252,133.899368 189.742233,134.174652 C172.863556,137.435364 158.562485,146.408813 144.080460,154.927536 
	C126.871857,165.050079 110.060364,175.890350 91.474442,183.470840	C87.751991,184.989090 83.890503,185.954819 79.915726,186.507263 
	C74.689781,187.233597 72.960999,185.875839 72.713654,180.432083 C72.426445,174.111099 72.684204,167.779007 72.584435,161.453064 
	C72.494614,155.757233 71.403831,155.099792 66.439766,158.109985 C52.487728,166.570404 38.494469,174.945221 23.695898,181.880966 
	C18.102198,184.502594 12.436678,186.858154 6.199942,187.487549 C4.517657,187.657303 2.872015,187.983566 1.279977,186.261017 
	C1.000000,144.979111 1.000000,103.958214 1.000000,62.468658 C2.227286,60.563202 3.754338,59.529312 5.584822,59.137413 
	C21.531107,55.723358 35.050968,47.082195 48.896194,39.127609 C64.186798,30.342592 79.005325,20.724512 95.021355,13.217394 
	C100.633209,10.586974 106.395378,8.449686 112.434227,7.180975 C118.753227,5.853409 121.109688,7.797761 121.326340,14.231797 
	C121.528061,20.222466 121.285561,26.220848 121.437881,32.212151 C121.559280,36.987453 122.704384,37.689415 126.870804,35.255119 
	C135.637573,30.133003 144.400314,25.014734 153.508392,20.512178 C164.144440,15.254263 175.018860,10.724446 186.926712,9.212770 
	C188.965561,8.953942 190.918350,8.656584 192.722473,10.732233 z"/>
        <path fill="#FFFEFE" opacity="1.000000" stroke="none"
          d="
M1.000000,186.497910 
	C12.684978,184.826157 23.154663,180.105835 33.215004,174.504990 
	C45.279278,167.788528 57.114887,160.661377 69.051445,153.715378 
	C70.734474,152.735977 72.428406,151.775330 75.032463,150.280731 
	C75.032463,160.444229 75.029678,169.666031 75.033432,178.887833 
	C75.035995,185.200760 75.055885,185.276352 81.388611,183.951080 
	C89.441933,182.265732 96.834526,178.816345 104.103249,175.095123 
	C121.156128,166.364914 137.108002,155.724289 153.818710,146.402527 
	C163.555161,140.971222 173.356125,135.646423 184.263885,132.796219 
	C186.865433,132.116440 189.470718,131.716568 192.575134,131.873550 
	C193.000000,150.797348 192.902756,169.595657 193.086441,188.391220 
	C193.120972,191.923737 192.628922,193.123962 188.624832,193.113190 
	C128.074524,192.950089 67.523300,192.917023 6.973434,193.148712 
	C1.817338,193.168427 -0.109547,192.081543 1.000000,186.497910 
z"/>
        <path fill="#FFFEFE" opacity="1.000000" stroke="none"
          d="
M193.000000,10.513435 
	C179.598938,11.756577 167.382019,16.474401 155.498917,22.266298 
	C144.419479,27.666477 133.784775,33.877640 123.297920,40.329731 
	C120.072136,42.314411 118.879044,42.321671 119.000008,38.006042 
	C119.237808,29.522152 118.923645,21.023149 119.140533,12.538138 
	C119.226509,9.174810 117.924377,8.557715 115.011627,9.057582 
	C107.213524,10.395848 100.020302,13.393023 92.977173,16.838667 
	C76.807373,24.749268 61.770851,34.638714 46.213642,43.619141 
	C36.845306,49.027027 27.436228,54.360638 17.296141,58.243290 
	C12.307050,60.153618 7.232181,61.718166 1.418441,61.966534 
	C1.000000,42.701511 1.031541,23.402901 0.957195,4.104700 
	C0.948601,1.873907 1.115876,0.913429 3.884792,0.918189 
	C65.939232,1.024850 127.993927,1.020290 190.048416,0.929093 
	C192.650208,0.925270 193.296997,1.633939 193.042511,4.042196 
	C192.833878,6.016538 193.000000,8.030481 193.000000,10.513435 
z"/>
        <path fill="#FFFDFD" opacity="1.000000" stroke="none"
          d="
M111.894333,103.999748 
	C111.893883,110.324715 111.884392,116.149780 111.898605,121.974777 
	C111.902779,123.684944 112.004410,125.284019 109.613640,125.608231 
	C96.015762,127.452240 84.770607,134.769180 73.330589,141.514099 
	C58.862865,150.044128 44.845997,159.360199 29.681618,166.665405 
	C23.995947,169.404388 18.092527,171.687927 12.335707,174.284042 
	C9.415670,175.600876 8.168638,175.180893 8.196724,171.623657 
	C8.316291,156.479507 8.275372,141.333755 8.216187,126.188889 
	C8.208044,124.105331 8.685310,122.810562 10.898923,122.155968 
	C26.876080,117.431213 40.790154,108.525230 54.984230,100.203857 
	C69.900093,91.459343 84.426201,81.998070 100.432831,75.255722 
	C111.865463,70.440041 111.894348,70.508621 111.894341,82.529533 
	C111.894341,89.519630 111.894341,96.509720 111.894333,103.999748 
z"/>
        <path fill="#FFFDFD" opacity="1.000000" stroke="none"
          d="
M142.352600,94.340439 
	C134.880814,98.749580 127.723381,102.971046 120.551117,107.167130 
	C120.315521,107.304962 119.928894,107.184616 119.066750,107.184616 
	C119.066750,100.076950 119.066818,92.993851 119.066742,85.910751 
	C119.066635,76.095490 119.067421,66.280228 119.065376,56.464962 
	C119.064964,54.489586 118.862411,52.640697 121.067085,51.306816 
	C138.705383,40.635239 156.126999,29.555784 175.654297,22.482622 
	C185.724213,18.835104 185.755447,18.921597 185.755524,29.715128 
	C185.755615,43.023956 185.763718,56.332798 185.747345,69.641609 
	C185.744980,71.570457 186.158737,73.475983 183.337494,74.301659 
	C168.664719,78.595787 155.754272,86.569221 142.352600,94.340439 
z"/>
      </svg>
    </Box>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
};

export default Logo;
