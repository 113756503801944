import { useQuery } from 'react-query'
import axios from "axios"
import Cookies from 'cookies'


export function useGetRequest(paramsArr, queryFunction) {
    return useQuery(paramsArr, queryFunction)
}

export function buildGetRequest(pathParamsArr, queryParamsArr, contextReq) {
    let params = {
        method: 'GET',
        withCredentials: true,
        url: ''
    }

    if (contextReq) {
        params.headers = {
            Cookie: getAuthCookie(contextReq, params)
        }
    }

    const baseUrl = process.env.NEXT_PUBLIC_API_URL
    params.url = buildURL(baseUrl, pathParamsArr, queryParamsArr)

    return function () {
        return axios(
            params
        ).then(res => res.data)
    }
}


export function buildPostRequest(pathParamsArr, bodyDict, contextReq) {
    let params = {
        method: 'POST',
        withCredentials: true,
        url: '',
        data: bodyDict,
    }

    if (contextReq) {
        params.headers = {
            Cookie: getAuthCookie(contextReq, params)
        }
    }

    const baseUrl = process.env.NEXT_PUBLIC_API_URL
    params.url = buildURL(baseUrl, pathParamsArr, [])

    return function () {
        return axios(
            params
        ).then(res => res.data)
    }
}

function getAuthCookie(contextReq) {
    const cookie = new Cookies(contextReq, null)
    const sessionCookie = cookie.get(process.env.COOKIE_NAME)

    return process.env.COOKIE_NAME + '=' + sessionCookie + ";"
}


function buildURL(baseUrl, pathParamsArr, queryParamsArr) {
    let url = pathParamsArr.reduce((url, param) => {
        if (['string', 'number'].includes(typeof param)) {
            url += (param + "/")
        } else {
            let urlParams = new URLSearchParams();
            Object.entries(param).map(([index, content]) => {
                urlParams.append(index, content)
            })
            url += ("?" + urlParams.toString())
        }
        return url
    }, baseUrl)

    queryParamsArr.forEach((queryParam, index) => {
        if (index == 0)
            url += '?'
        if (index != 0)
            url += '&'

        url += queryParam.key + '=' + queryParam.value
    })

    return url
}