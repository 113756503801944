import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import {
  alpha,
  useTheme,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import componentsOverride from '../theme/overrides';
import { ptBR } from '@material-ui/core/locale';
// ----------------------------------------------------------------------

ThemePrimaryColor.propTypes = {
  children: PropTypes.node,
};

export default function ThemePrimaryColor({ children }) {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme]
  );

  const theme = createTheme(themeOptions, ptBR);
  //theme.components = componentsOverride(theme); - Retirei para poder aplicar a tradução para português dos componentes em toda plataforma

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
